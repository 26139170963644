import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rounds"
    }}>{`Rounds`}</h1>
    <p>{`CSS has classes that change the `}<inlineCode parentName="p">{`border-radius`}</inlineCode>{` property to maintain standardization`}</p>
    <div className='grid-box mb-3 display-flex justify-content-space-between' style={{
      "height": "auto"
    }}>
  <div className='background-gray p-2 text-center display-flex align-items-center justify-content-center rounded' style={{
        "width": "50px",
        "height": "50px"
      }}>
    Item
  </div>
  <div className='background-gray p-2 text-center display-flex align-items-center justify-content-center border-radius' style={{
        "width": "50px",
        "height": "50px"
      }}>
    Item
  </div>
  <div className='background-gray p-2 text-center display-flex align-items-center justify-content-center border-radius-medium' style={{
        "width": "50px",
        "height": "50px"
      }}>
    Item
  </div>
  <div className='background-gray p-2 text-center display-flex align-items-center justify-content-center border-radius-none' style={{
        "width": "50px",
        "height": "50px"
      }}>
    Item
  </div>
    </div>
    <h4>.rounded</h4>
Apply `border-radius: 50%`
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='rounded'>...</div>
`}</code></pre>
    <h4>.border-radius</h4>
Apply `border-radius: 5px`
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='border-radius'>...</div>
`}</code></pre>
    <h4>.border-radius-minor</h4>
Apply `border-radius: 2px`
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='border-radius-minor'>...</div>
`}</code></pre>
    <h4>.border-radius-medium</h4>
Apply `border-radius: 8px`
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='border-radius-medium'>...</div>
`}</code></pre>
    <h4>.border-radius-none</h4>
Apply `border-radius: none`
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='border-radius-none'>...</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      